import React, { useEffect, useState } from 'react'
import { GoogleSpreadsheet } from 'google-spreadsheet';
import DashboardCard from './component/dashboardCard';
import PieChart from '../../../components/Controls/chart/pieChart';
import VerticalBarChart from '../../../components/Controls/chart/verticalBarChart';
import MultipleLineChart from '../../../components/Controls/chart/multipleLineChart';
import ComboBarLine from '../../../components/Controls/chart/comboBarLine';


function Dashboard() {

    const SPREADSHEET_ID = "1W76-UZTeEct5N1atG-RignbdzJbDkyN3jdC6cvyxcUg";

    const [baseCurrency, setBaseCurrency] = useState("");
    const [activeLoans, setActiveLoans] = useState(0);
    const [totalLoanBook, setTotalLoanBook] = useState(0);
    const [revenue, setRevenue] = useState(0);
    const [annualReturn, setAnnualReturn] = useState(0);
    const [averageLTV, setAverageLTV] = useState(0);
    const [redemption, setRedemption] = useState(0);
    const [cashFlow, setCashFlow] = useState(0);

    const [loanBookData, setLoanBookData] = useState([]);
    const [revenueData, setRevenueData] = useState([]);
    const [singaporePropertiesData, setSingaporePropertiesData] = useState([]);
    const [uKPropertiesData, setUKPropertiesData] = useState([]);
    const [othersData, setOthersData] = useState([]);
    const [statisticsData, setStatisticsData] = useState([]);
    const [firstChargeCapitalData, setFirstChargeCapitalData] = useState([]);
    const [historicalData, setHistoricalData] = useState([]);
    const [compareLoansData, setCompareLoansData] = useState([]);

    const [statisticsChartData, setStatisticsChartData] = useState([]);

    const [capitalLoanBookLabelsChart, setCapitalLoanBookLabelsChart] = useState([]);
    const [capitalLoanBookValuesChart, setCapitalLoanBookValuesChart] = useState([]);

    const [monthlyRevenueLabelsChart, setMonthlyRevenueLabelsChart] = useState([]);
    const [monthlyRevenueValuesChart, setMonthlyRevenueValuesChart] = useState([]);

    const [monthlyInterestCashFlowLabelsChart, setMonthlyInterestCashFlowLabelsChart] = useState([]);
    const [monthlyInterestCashFlowValuesChart, setMonthlyInterestCashFlowValuesChart] = useState([]);

    const [exchangeRateChartData, setExchangeRateChartData] = useState(undefined);

    const [loanOriginationLabelsChart, setLoanOriginationLabelsChart] = useState(undefined);
    const [loanOriginationBarChart, setLoanOriginationBarChart] = useState(undefined);
    const [loanOriginationLineChart, setLoanOriginationLineChart] = useState(undefined);


    // const _getCellValueByDoc = async (_doc, _sheetsByTitle, _formulaCell) => {
    //     const sheet = _doc.sheetsByTitle[_sheetsByTitle];
    //     await sheet.loadCells(_formulaCell);
    //     const cell = sheet.getCellByA1(_formulaCell);
    //     const value = cell.formattedValue;
    //     // console.log(`formula "${cell.formula}": totalLoanBookvalue : ${value}`);
    //     return value;
    // }

    const _getCellValue = async (sheet, _formulaCell) => {
        const cell = sheet.getCellByA1(_formulaCell);
        const value = cell.formattedValue;
        // console.log(`formula "${cell.formula}": totalLoanBookvalue : ${value}`);
        return value;
    }

    const _getTableData = async (sheet, startRow, endRow, startColumn, endColumn) => {
        const extractedData = [];
        for (let i = startRow - 1; i < endRow; i++) {
            const row = [];
            for (let j = startColumn - 1; j < endColumn; j++) {
                const cell = sheet.getCell(i, j);
                row.push(cell.formattedValue);
            }
            extractedData.push(row);
        }
        return extractedData;
    }

    const convertCellReferenceToIndices = (cellReference) => {
        const columnLetter = cellReference.match(/[A-Z]+/)[0];
        const rowNumber = parseInt(cellReference.match(/\d+/)[0]);

        // Convert the column letter to numeric index
        let columnIndices = 0;
        for (let i = 0; i < columnLetter.length; i++) {
            columnIndices = columnIndices * 26 + (columnLetter.charCodeAt(i) - 64);
        }

        return [rowNumber, columnIndices];
    }

    const _getChartData = async (sheet, startCell, endCell, valueType = "formattedValue") => {

        let _startCell = convertCellReferenceToIndices(startCell);
        let _endCell = convertCellReferenceToIndices(endCell);

        let startRow = _startCell[0];
        let startColumn = _startCell[1];
        let endRow = _endCell[0];
        let endColumn = _endCell[1];

        const extractedData = [];
        for (let i = startRow - 1; i < endRow; i++) {
            const row = [];
            for (let j = startColumn - 1; j < endColumn; j++) {
                const cell = sheet.getCell(i, j);
                // console.log('cell',cell.valueType)
                if (valueType === 'formattedValue') {
                    row.push(cell.formattedValue);
                } else {
                    row.push(cell.value);
                }
            }
            extractedData.push(row);
        }
        return extractedData;
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const doc = new GoogleSpreadsheet(SPREADSHEET_ID);
                await doc.useServiceAccountAuth(require('./../../../googleCredentials.json'));
                await doc.loadInfo();
                // const sheet = doc.sheetsByIndex[0]; // the first sheet
                // const sheetDashboard = doc.sheetsByTitle['Dashboard'];


                const sheet = doc.sheetsByIndex[0];
                await sheet.loadCells('B9:W106');

                // __________ Dashboard Card __________
                const getBaseCurrency = await _getCellValue(sheet, 'B9')
                setBaseCurrency(getBaseCurrency);

                const getActiveLoans = await _getCellValue(sheet, 'E9')
                setActiveLoans(getActiveLoans);

                const getTotalLoanBook = await _getCellValue(sheet, 'G19')
                setTotalLoanBook(getTotalLoanBook);

                const getRevenue = await _getCellValue(sheet, 'K9')
                setRevenue(getRevenue);

                const getAnnualReturn = await _getCellValue(sheet, 'N9')
                setAnnualReturn(getAnnualReturn);

                const getAverageLTV = await _getCellValue(sheet, 'Q9')
                setAverageLTV(getAverageLTV);

                const getRedemption = await _getCellValue(sheet, 'T9')
                setRedemption(getRedemption);

                const getCashFlow = await _getCellValue(sheet, 'W9')
                setCashFlow(getCashFlow);
                // __________ Dashboard Card __________


                // __________ Table Data__________
                const _getLoanBookData = await _getTableData(sheet, 19, 24, 2, 7)
                setLoanBookData(_getLoanBookData);

                const _getRevenueData = await _getTableData(sheet, 29, 34, 2, 7)
                setRevenueData(_getRevenueData);

                const _getSingaporePropertiessData = await _getTableData(sheet, 39, 42, 2, 7)
                setSingaporePropertiesData(_getSingaporePropertiessData);

                const _getUKPropertiesData = await _getTableData(sheet, 47, 50, 2, 7)
                setUKPropertiesData(_getUKPropertiesData);

                const _getOthersData = await _getTableData(sheet, 55, 58, 2, 7)
                setOthersData(_getOthersData);

                const _getStatisticsData = await _getTableData(sheet, 63, 66, 2, 7)
                setStatisticsData(_getStatisticsData);

                const _getFirstChargeCapitalData = await _getTableData(sheet, 71, 74, 2, 7)
                setFirstChargeCapitalData(_getFirstChargeCapitalData);

                const _getHistoricalData = await _getTableData(sheet, 80, 106, 2, 13)
                setHistoricalData(_getHistoricalData);

                const _getCompareLoansData = await _getTableData(sheet, 79, 98, 15, 23)
                setCompareLoansData(_getCompareLoansData);
                // __________ Table Data __________


                // __________ Chart Data __________
                const _getStatisticsChartData = await _getChartData(sheet, 'B63', 'D65', 'value');
                setStatisticsChartData(_getStatisticsChartData);
                // __________ Chart Data __________



                const sheetCCombined = doc.sheetsByTitle['cCombined'];
                await sheetCCombined.loadCells('D40:BS45');

                const _getCapitalLoanBookLabelsChart = await _getChartData(sheetCCombined, 'D40', 'BS40');
                setCapitalLoanBookLabelsChart(_getCapitalLoanBookLabelsChart[0]);
                const _getCapitalLoanBookValuesChart = await _getChartData(sheetCCombined, 'D43', 'BS43', 'value');
                setCapitalLoanBookValuesChart(_getCapitalLoanBookValuesChart[0]);


                const _getMonthlyRevenueLabelsChart = await _getChartData(sheetCCombined, 'D40', 'BS40');
                setMonthlyRevenueLabelsChart(_getMonthlyRevenueLabelsChart[0]);
                const _getMonthlyRevenueValuesChart = await _getChartData(sheetCCombined, 'D44', 'BS44', 'value');
                setMonthlyRevenueValuesChart(_getMonthlyRevenueValuesChart[0]);

                const _getMonthlyInterestCashFlowLabelsChart = await _getChartData(sheetCCombined, 'D40', 'BR40');
                setMonthlyInterestCashFlowLabelsChart(_getMonthlyInterestCashFlowLabelsChart[0]);
                const _getMonthlyInterestCashFlowValuesChart = await _getChartData(sheetCCombined, 'D45', 'BR45', 'value');
                setMonthlyInterestCashFlowValuesChart(_getMonthlyInterestCashFlowValuesChart[0]);


                const sheetBackend = doc.sheetsByTitle['Backend'];
                await sheetBackend.loadCells('A2:P79');

                // __________ Exchange Rate Chart Data __________
                const _getExchangeRateLablesChart = await _getChartData(sheetBackend, 'A3', 'A79');
                const _getExchangeRateValueGBP = await _getChartData(sheetBackend, 'B3', 'B79');
                const _getExchangeRateValueUSD = await _getChartData(sheetBackend, 'C3', 'C79');
                const _getExchangeRateValueEUR = await _getChartData(sheetBackend, 'D3', 'D79');
                let exchangeRateLablesChartArr = _getExchangeRateLablesChart.map((d) => d[0]);
                let exchangeRateValueGBPArr = _getExchangeRateValueGBP.map((d) => d[0]);
                let exchangeRateValueUSDArr = _getExchangeRateValueUSD.map((d) => d[0]);
                let exchangeRateValueEURArr = _getExchangeRateValueEUR.map((d) => d[0]);

                const ExchangeRateData = {
                    labels: exchangeRateLablesChartArr,
                    datasets: [
                        {
                            label: 'GBP',
                            data: exchangeRateValueGBPArr ? exchangeRateValueGBPArr : []
                        },
                        {
                            label: 'USD',
                            data: exchangeRateValueUSDArr ? exchangeRateValueUSDArr : []
                        },
                        {
                            label: 'EUR',
                            data: exchangeRateValueEURArr ? exchangeRateValueEURArr : []
                        }
                    ],
                };
                setExchangeRateChartData(ExchangeRateData);
                // __________ Exchange Rate Chart Data __________


                // __________ Loan Origination __________
                const _getLoanOriginationLablesChart = await _getChartData(sheetBackend, 'N4', 'N24');
                const _getLoanOriginationAmount = await _getChartData(sheetBackend, 'O4', 'O24', 'value');
                const _getLoanOriginationCount = await _getChartData(sheetBackend, 'P4', 'P24');
                let LoanOriginationLablesChartArr = _getLoanOriginationLablesChart.map((d) => d[0]);
                let LoanOriginationAmountArr = _getLoanOriginationAmount.map((d) => d[0]);
                let getLoanOriginationCountArr = _getLoanOriginationCount.map((d) => d[0]);
                setLoanOriginationLabelsChart(LoanOriginationLablesChartArr)
                setLoanOriginationBarChart(LoanOriginationAmountArr.map((d) => parseFloat(d.toFixed(2))))
                setLoanOriginationLineChart(getLoanOriginationCountArr)
                // __________ Loan Origination __________






            } catch (error) {
                console.error('Error fetching spreadsheet data:', error);
            }
        };

        fetchData();
    }, []);


    const ExchangeRateBlankData = {
        labels: "",
        datasets: [
            {
                label: 'GBP',
                data: [],
            },
            {
                label: 'USD',
                data: [],
            },
            {
                label: 'EUR',
                data: [],
            }
        ],
    };

    return (
        <div className='wrapper'>
            <div className='content-wrapper'>
                <div className='content'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-12'>
                                <h1 className="d-flex justify-content-center my-4">Rikvin Captial Loan Dashboard</h1>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-6 col-md-3'>
                                <DashboardCard
                                    title="Base Currency"
                                    number={baseCurrency}
                                    faIcon="fa-usd"
                                    iconBackgroundClass="bg-success" />
                            </div>
                            <div className='col-6 col-md-3'>
                                <DashboardCard
                                    title="Active Loans"
                                    number={activeLoans}
                                    faIcon="fa-users"
                                    iconBackgroundClass="bg-danger" />
                            </div>
                            <div className='col-6 col-md-3'>
                                <DashboardCard
                                    title="Total Loan Book"
                                    number={totalLoanBook}
                                    faIcon="fa-usd"
                                    iconBackgroundClass="bg-warning" />
                            </div>
                            <div className='col-6 col-md-3'>
                                <DashboardCard
                                    title="May Revenue"
                                    number={revenue}
                                    faIcon="fa-usd"
                                    iconBackgroundClass="bg-warning" />
                            </div>
                            <div className='col-6 col-md-3'>
                                <DashboardCard
                                    title="Annual Return"
                                    number={annualReturn}
                                    faIcon="fa-gauge-simple"
                                    iconBackgroundClass="bg-info" />
                            </div>
                            <div className='col-6 col-md-3'>
                                <DashboardCard
                                    title="Average LTV"
                                    number={averageLTV}
                                    faIcon="fa-gauge-simple"
                                    iconBackgroundClass="bg-info" />
                            </div>

                            <div className='col-6 col-md-3'>
                                <DashboardCard
                                    title="May Redemption"
                                    number={redemption}
                                    faIcon="fa-usd"
                                    iconBackgroundClass="bg-warning" />
                            </div>
                            <div className='col-6 col-md-3'>
                                <DashboardCard
                                    title="May Cash Flow"
                                    number={cashFlow}
                                    faIcon="fa-usd"
                                    iconBackgroundClass="bg-warning" />
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12 col-md-6 mb-4'>
                                <div className='card chart-card'>
                                    <p className='chatTitle'>Rikvin Capital Loan Book</p>
                                    <VerticalBarChart
                                        legendChart="Rikvin Capital Loan Book"
                                        // labelsChart={['Nov 2017', 'Dec 2017', 'Jan 2018', 'Feb 2018', 'Mar 2018']}
                                        // valuesChart={[1515808, 1523402, 1518272, 1568985, 1530909]}
                                        labelsChart={capitalLoanBookLabelsChart}
                                        // valuesChart={capitalLoanBookValuesChart}
                                        valuesChart={capitalLoanBookValuesChart.map((d) => parseFloat(d.toFixed(2)))}
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-md-6 mb-4'>
                                <div className='card chart-card'>
                                    <p className='chatTitle'>Loan Origination</p>
                                    <ComboBarLine
                                        labelsChart={loanOriginationLabelsChart}
                                        barChartValue={loanOriginationBarChart}
                                        lineChartValue={loanOriginationLineChart}
                                        barChartDatasetsLable={'Amount'}
                                        lineChartDatasetsLable={'Count'}
                                    />
                                </div>
                            </div>
                        
                            <div className='col-12 col-md-6 mb-4'>
                                <div className='card chart-card'>
                                    <p className='chatTitle'>Monthly Revenue</p>
                                    <VerticalBarChart
                                        legendChart="Monthly Revenue"
                                        labelsChart={monthlyRevenueLabelsChart}
                                        valuesChart={monthlyRevenueValuesChart.map((d) => parseFloat(d.toFixed(2)))}
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-md-6 mb-4'>
                                <div className='card chart-card'>
                                    <p className='chatTitle'>Monthly Interest Cash Flow</p>
                                    <VerticalBarChart
                                        legendChart="Monthly Interest Cash Flow"
                                        labelsChart={monthlyInterestCashFlowLabelsChart}
                                        valuesChart={monthlyInterestCashFlowValuesChart.map((d) => parseFloat(d.toFixed(2)))}
                                    />
                                </div>
                            </div>
                       
                            <div className='col-12 col-md-6 mb-4'>
                                <div className='card chart-card'>
                                    <p className='chatTitle'>Exchange Rate (vs SGD)</p>
                                    <MultipleLineChart
                                        legendChart="Exchange Rate (vs SGD)"
                                        multipleLineChartData={exchangeRateChartData !== undefined ? exchangeRateChartData : ExchangeRateBlankData}
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-md-3 mb-4'>
                                <div className='card chart-card'>
                                    <p className='chatTitle'>Geographic Distribution</p>
                                    <PieChart
                                        legendChart="Geographic Distribution"
                                        labelsChart={statisticsChartData.map(([country]) => country)}
                                        valuesChart={statisticsChartData.map(([country, noOfLoans, totalLoan]) => parseFloat(totalLoan.toFixed(0)))}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12 col-md-6 mb-4'>
                                <div className='card dashoard-table-card'>
                                    <div className='table-responsive'>
                                        <table className="table table-striped table-hover">
                                            <thead>
                                                <tr>
                                                    <th colSpan="6">LOAN BOOK</th>
                                                </tr>
                                                <tr>
                                                    <th></th>
                                                    <th>GBP</th>
                                                    <th>SGD</th>
                                                    <th>USD</th>
                                                    <th>EUR</th>
                                                    <th>Total(SGD)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {loanBookData.map((row, index) => (
                                                    <tr key={index}>
                                                        {row.map((cell, cellIndex) => (
                                                            <td key={cellIndex}>{cell}</td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className='col-12 col-md-6 mb-4'>
                                <div className='card dashoard-table-card'>
                                    <div className='table-responsive'>
                                        <table className="table table-striped table-hover">
                                            <thead>
                                                <tr>
                                                    <th colSpan="6">MAY REVENUE</th>
                                                </tr>
                                                <tr>
                                                    <th></th>
                                                    <th>GBP</th>
                                                    <th>SGD</th>
                                                    <th>USD</th>
                                                    <th>EUR</th>
                                                    <th>Total(SGD)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {revenueData.map((row, index) => (
                                                    <tr key={index}>
                                                        {row.map((cell, cellIndex) => (
                                                            <td key={cellIndex}>{cell}</td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className='col-12 col-md-6 mb-4'>
                                <div className='card dashoard-table-card'>
                                    <div className='table-responsive'>
                                        <table className="table table-striped table-hover">
                                            <thead>
                                                <tr>
                                                    <th colSpan="6">SINGAPORE PROPERTIES</th>
                                                </tr>
                                                <tr>
                                                    <th></th>
                                                    <th>Residential</th>
                                                    <th>Commercial</th>
                                                    <th>Industrial</th>
                                                    <th>Mixed</th>
                                                    <th>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {singaporePropertiesData.map((row, index) => (
                                                    <tr key={index}>
                                                        {row.map((cell, cellIndex) => (
                                                            <td key={cellIndex}>{cell}</td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className='col-12 col-md-6 mb-4'>
                                <div className='card dashoard-table-card'>
                                    <div className='table-responsive'>
                                        <table className="table table-striped table-hover">
                                            <thead>
                                                <tr>
                                                    <th colSpan="6">U.K. PROPERTIES</th>
                                                </tr>
                                                <tr>
                                                    <th></th>
                                                    <th>Residential</th>
                                                    <th>Commercial</th>
                                                    <th>Industrial</th>
                                                    <th>Mixed</th>
                                                    <th>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {uKPropertiesData.map((row, index) => (
                                                    <tr key={index}>
                                                        {row.map((cell, cellIndex) => (
                                                            <td key={cellIndex}>{cell}</td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className='col-12 col-md-6 mb-4'>
                                <div className='card dashoard-table-card'>
                                    <div className='table-responsive'>
                                        <table className="table table-striped table-hover">
                                            <thead>
                                                <tr>
                                                    <th colSpan="6">OTHERS</th>
                                                </tr>
                                                <tr>
                                                    <th></th>
                                                    <th>Personal Guarantee</th>
                                                    <th>Shares</th>
                                                    <th>Vehicles</th>
                                                    <th>Watches</th>
                                                    <th>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {othersData.map((row, index) => (
                                                    <tr key={index}>
                                                        {row.map((cell, cellIndex) => (
                                                            <td key={cellIndex}>{cell}</td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className='col-12 col-md-6 mb-4'>
                                <div className='card dashoard-table-card'>
                                    <div className='table-responsive'>
                                        <table className="table table-striped table-hover">
                                            <thead>
                                                <tr>
                                                    <th colSpan="6">STATISTICS</th>
                                                </tr>
                                                <tr>
                                                    <th></th>
                                                    <th>No. of Loans</th>
                                                    <th>Total Loan</th>
                                                    <th>% of Portfolio</th>
                                                    <th>Total Value</th>
                                                    <th>W.A. LTV</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {statisticsData.map((row, index) => (
                                                    <tr key={index}>
                                                        {row.map((cell, cellIndex) => (
                                                            <td key={cellIndex}>{cell}</td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className='col-12 col-md-6 mb-4'>
                                <div className='card dashoard-table-card'>
                                    <div className='table-responsive'>
                                        <table className="table table-striped table-hover">
                                            <thead>
                                                <tr>
                                                    <th colSpan="6">FIRST CHARGE CAPITAL</th>
                                                </tr>
                                                <tr>
                                                    <th></th>
                                                    <th>GBP</th>
                                                    <th>SGD</th>
                                                    <th>USD</th>
                                                    <th>EUR</th>
                                                    <th>Total(SGD)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {firstChargeCapitalData.map((row, index) => (
                                                    <tr key={index}>
                                                        {row.map((cell, cellIndex) => (
                                                            <td key={cellIndex}>{cell}</td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className='col-12 col-md-12 mb-4'>
                                <div className='card dashoard-table-card'>
                                    <div className='table-responsive'>
                                        <table className="table table-striped table-hover">
                                            <thead>
                                                <tr>
                                                    <th colSpan="12">HISTORICAL DATA</th>
                                                </tr>
                                                <tr>
                                                    <th>Month</th>
                                                    <th>Loan Book</th>
                                                    <th>Investors</th>
                                                    <th>Bond</th>
                                                    <th>Banks</th>
                                                    <th>Equity</th>
                                                    <th>Revenue</th>
                                                    <th>Cash Flow</th>
                                                    <th>Investor</th>
                                                    <th>Bond</th>
                                                    <th>Bank</th>
                                                    <th>Income</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {historicalData.map((row, index) => (
                                                    <tr key={index}>
                                                        {row.map((cell, cellIndex) => (
                                                            <td key={cellIndex}>{cell}</td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className='col-12 col-md-12 mb-4'>
                                <div className='card dashoard-table-card'>
                                    <div className='table-responsive'>
                                        <table className="table table-striped table-hover">
                                            <thead>
                                                <tr>
                                                    <th colSpan="3">U.K. LOANS</th>
                                                    <th colSpan="3">SINGAPORE LOANS</th>
                                                    <th colSpan="3">OTHER LOANS</th>
                                                </tr>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Balance</th>
                                                    <th>LTV name</th>
                                                    <th>Name</th>
                                                    <th>Balance</th>
                                                    <th>LTV name</th>
                                                    <th>Name</th>
                                                    <th>Balance</th>
                                                    <th>LTV name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {compareLoansData.map((row, index) => (
                                                    <tr key={index}>
                                                        {row.map((cell, cellIndex) => (
                                                            <td key={cellIndex}>{cell}</td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>



                        

                    </div>
                </div>

            </div>
        </div>
    )
}

export default Dashboard