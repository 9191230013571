import { configureStore } from "@reduxjs/toolkit";

import dashboardReducer from './features/dashboard/reducerSlices/dashboardSlice';

export const store = configureStore({
    reducer: {
        dashboards: dashboardReducer,
    }
});

export default store;