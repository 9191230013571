import React from 'react'
import { Bar } from 'react-chartjs-2';

function ComboBarLine(props) {

    const data = {
        // labels: ['January', 'February', 'March', 'April', 'May', 'June'],
        labels: props.labelsChart,
        datasets: [
            {
                type: 'bar',
                label: props.barChartDatasetsLable ? props.barChartDatasetsLable : 'Bar Dataset',
                // data: [10, 20, 30, 40, 50, 60],
                data: props.barChartValue,
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                yAxisID: 'y',
            },
            {
                type: 'line',
                label: props.lineChartDatasetsLable ? props.lineChartDatasetsLable : 'Line Dataset',
                // data: [50, 40, 30, 20, 10, 0],
                data: props.lineChartValue,
                borderColor: 'rgba(255, 99, 132, 0.8)',
                fill: false,
                yAxisID: 'y1',
                pointStyle: 'star',
                pointRadius: 8,
            },
        ],
    };

    const options = {
        responsive: true,
        scales: {
            x: {
                display: true,
            },
            y: {
                type: 'linear',
                display: true,
                position: 'left',
                beginAtZero: true,
            },
            y1: {
                type: 'linear',
                display: false,
                position: 'right',
                grid: {
                    drawOnChartArea: false, // only want the grid lines for one axis to show up
                },
            }
        },
    };


    return (
        <div>
            <Bar data={data} options={options} />
        </div>
    )
}

export default ComboBarLine;