import React from 'react';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { _formatNumber } from '../../../common/common';

function PieChart(props) {

    const backgroundColor = ['rgba(255, 99, 132, 0.2)',  'rgba(75, 192, 192, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(153, 102, 255, 0.2)', 'rgba(201, 203, 207, 0.2)'];
    const borderColor = ['rgb(255, 99, 132)', 'rgb(75, 192, 192)', 'rgb(54, 162, 235)', 'rgb(153, 102, 255)', 'rgb(201, 203, 207)']

    const data = {
        // labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        labels: props.labelsChart,
        datasets: [
            {
                // label: "chart example",
                label: props.legendChart,
                // data: [25, 26, 395, 12, 15, 85],
                data: props.valuesChart,
                backgroundColor: props.backgroundColor ? props.backgroundColor : backgroundColor,
                borderColor: props.backgroundColor ? '' : borderColor,
                borderWidth: props.backgroundColor ? 0 : 1
            },
        ],
    };
    const options = {
        plugins: {
            datalabels: {
                formatter: (value, ctx) => {
                    let sum = 0;
                    let dataArr = ctx.chart.data.datasets[0].data;
                    dataArr.map(data => 
                        sum += data
                    );
                    let percentage = (value * 100 / sum).toFixed(2) + "%";
                    return percentage;
                },
                // color: '#fff',
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        var data = context.dataset.data,
                            label = context.label,
                            currentValue = context.raw,
                            total = 0;

                        for (var i = 0; i < data.length; i++) {
                            total += data[i];
                        }
                        var percentage = parseFloat((currentValue / total * 100).toFixed(1));
                        let formatNumber =_formatNumber(currentValue);
                        return label + ": " + formatNumber + ' (' + percentage + '%)';
                    }
                }
            }
        },


        // plugins: {
        //     legend: {
        //         display: true,
        //         position: 'bottom',
        //         labels: { sort: true },
        //         datalabels: {
        //             display: true,
        //             color: 'blue',
        //             labels: {
        //                 title: {
        //                     font: {
        //                         weight: 'bold'
        //                     }
        //                 },
        //                 value: {
        //                     color: 'green'
        //                 }
        //             }
        //         }
        //     },
        // },
        responsive: true,
        maintainAspectRatio: true,
    };


    return (
        <>
            <Pie data={data} plugins={[ChartDataLabels]} options={options} />
        </>
    );
}
export default PieChart;