import React from 'react';

function DashboardCard(props) {
    return (
        <div className={`dashboard-card`}>
            <div className={`dashboard-card-circle ${props.iconBackgroundClass ? props.iconBackgroundClass : ''}`}>
                <i className={`fa fa-xl text-white ${props.faIcon ? props.faIcon : 'fa-arrow-trend-up'}  `}></i>
            </div>
            <div className="w-100 ps-3 ">
                <div className="dashboard-card-title">
                    {props.title}
                </div>
                <div className="dashboard-card-number">
                    {props.number}
                </div>
            </div>
        </div>
    );
}

export default DashboardCard;