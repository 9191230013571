import {
    createSlice,
    nanoid
}
from '@reduxjs/toolkit';


const initialState = {
    graphCount: {
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
};


export const dashbaordSlice = createSlice({
    name: 'dashboards',
    initialState,
    reducers: {
        updateGraphCountsStatus: (state) => {
            state.graphCount.status = "DONE";
        }
    },
    
});

export const {
    updateGraphCountsStatus,
} = dashbaordSlice.actions;

export default dashbaordSlice.reducer

export const selectGraphCounts = state => state.dashboards.graphCount.data;
export const selectGraphCountStatus = state => state.dashboards.graphCount.status;